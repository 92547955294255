import React, { useEffect, useMemo, useState } from 'react';
import {
  BestMatchesTable,
  ChipFeature,
  ScholaReview,
  SchoolDetails,
  SponsoredSchool,
  SurveyFeedback,
} from './components';
import { _searchSchools } from 'controllers/schools/schools_controller';
import { _saveSearchHistory } from 'controllers/search/search_controller';
import {
  CATEGORY_LABELS,
  convertQueryToState,
  getSelectionsFeatures,
  transformSchoolsForTable,
  transformSponsoredSchool,
} from './utils';
// styles
import styles from './schola-match-results.module.scss';
import { ContactSchoolModal } from '../school-profile/contact-school/components';
import { useDispatch, useSelector } from 'react-redux';
import { openContact } from 'redux/slices/contact-school.slice';
import { useStatus } from 'hooks/useStatus';
import Skeleton from 'components/skeleton/Skeleton';
import BannerSteps from './components/banner-steps/banner-steps';
import { planName } from 'util/schoolUtils';

const axios = require('axios');

export const ScholaMatchResults = ({ location }) => {
  const { isContactOpen } = useSelector((state) => state.contactSchool);
  const dispatch = useDispatch();
  const [contactSchoolSelected, setContactSchoolSelected] = useState(false);

  const [search, setSearch] = useState([]);
  const [sponsoredSchool, setSponsoredSchool] = useState(null);
  const [labels, setLabels] = useState([]);
  const [featuresSet, setFeaturesSet] = useState(null);
  const [source, setSource] = useState('Schola Results A');
  const [searchHistoryId, setSearchHistoryId] = useState(null);
  const [externalCampaignId, setExternalCampaignId] = useState(null);

  const { onLoading, onSuccess, onError, status } = useStatus();

  const saveSearchhistory = (search, schoolsIds, selectedSchoolGrade, selectedSchoolTypes, selectedFeatures, ip) => {
    let schoolTypes = selectedSchoolTypes;
    let featuresSelected = selectedFeatures;
    let grades = selectedSchoolGrade;
    let temp = {};
    temp.address = search.address;
    if (search.state && search.state !== '' && search.state !== 'undefined') temp.state = search.state;
    if (search.zip && search.zip !== '') temp.zip = search.zip;
    temp.lat = search.lat;
    temp.lon = search.lon;
    temp.radius = search.radius || '5';
    temp.ip = ip || '';

    if (grades) {
      temp.grades = [grades];
    } else {
      temp.grades = ['all'];
    }

    if (schoolTypes?.indexOf('all schools') === -1) {
      let _schoolTypes = [];
      schoolTypes.forEach((sType, i) => {
        _schoolTypes.push(sType);
      });
      if (_schoolTypes.length > 0) {
        temp.school_types = _schoolTypes;
      }
    }
    let features = [];
    featuresSelected &&
      featuresSelected.split(',').forEach((feature, i) => {
        if (feature) features.push({ i: Number(feature), w: 50 });
      });

    if (features.length > 0) temp.f = features;

    temp.schools = schoolsIds.map((s, i) => {
      return {
        schoolId: s.id,
        position: i + 1,
        features_matched: s.featuresMatched,
        match_percent: features.length > 0 ? Math.round((s.featuresMatched / features.length) * 100) : 100,
      };
    });

    _saveSearchHistory(temp).then((res) => {
      setSearchHistoryId(res.id);
    });
  };

  const [autoFillForm, setAutoFillForm] = useState({});

  const load = async () => {
    if (location.query.source) {
      setSource(location.query.source);
    }
    if (location.query.external_campaign_id) {
      setExternalCampaignId(location.query.external_campaign_id);
    }
    const searchObject = convertQueryToState({ location });

    const matchFromJF = JSON.parse(localStorage.getItem('scholamatchinfoV5'));

    let preferedGrade;

    preferedGrade = location.query['grades[0]'] || matchFromJF.grade;

    const parentName = matchFromJF.parentName;
    const autoFillData = {
      firstName: parentName.split(' ')[0] || '',
      lastName: parentName.split(' ')[1] || '',
      email: matchFromJF.email || '',
      grade: location.query['grades[0]'] || '',
      zipcode: location.query.zip || '',
      studentName: matchFromJF.studentName || '',
      phone: matchFromJF.phone || '',
    };

    setAutoFillForm(autoFillData);

    const { selectedFeatures, featuresBucket } = getSelectionsFeatures({ answers: matchFromJF?.answers || undefined });
    let selectedTypes;
    // Collect all school_types from location.query
    const schoolTypes = [];
    Object.keys(location.query).forEach((key) => {
      if (key.startsWith('school_types[')) {
        schoolTypes.push(location.query[key]);
      }
    });
    selectedTypes = schoolTypes;
    // If schoolTypes is empty, assign selectedTypes from localstorage
    if (schoolTypes.length === 0) {
      selectedTypes = featuresBucket[CATEGORY_LABELS.type].bucket.map((item) => item.replace(/\+/g, ' '));
    }

    setLabels(featuresBucket.labels);

    setFeaturesSet(featuresBucket);

    const ipData = await axios.get('https://api.ipify.org/?format=json');

    onLoading();
    _searchSchools(searchObject.search.defaultQuery)
      .then((response) => response.json())
      .then(({ results }) => {
        /** filter schools by grade and type requested */
        let filteredSchools = results;

        filteredSchools = filteredSchools.filter((school) => school.grades.includes(preferedGrade.trim()));

        filteredSchools = filteredSchools.filter((school) => selectedTypes.includes(school.type));

        // filter by states_served in case the search includes online/virtual schools
        const state = location.query.state;

        if (selectedTypes.includes('virtual/online')) {
          filteredSchools = filteredSchools.filter((school) => {
            const statesServed = school.states_served ? school.states_served.split(',') : null;

            return statesServed ? statesServed.includes(state.trim()) : true;
          });
        }

        /**
         * Applying score value, and sorting schools based on it
         * */
        const scoringSchools = filteredSchools
          .map((school) => {
            let score = 0;
            let featuresMatched = 0;

            if (selectedTypes.includes(school.type)) score += 100;

            const featuresBySchool = school.features_weightage.map((item) => item.feature_id);

            featuresBySchool.forEach((featureId) => {
              if (selectedFeatures.has(featureId)) {
                score += 50;
                featuresMatched++;
              }
            });

            const matchLevel = score >= 200 ? 'good' : 'normal';

            const isScholaPro = planName(school) !== 'scholabasics';

            return {
              ...school,
              score,
              matchLevel,
              featuresMatched,
              featuresBySchool,
              isScholaPro,
            };
          })
          .sort((a, b) => b.featuresMatched - a.featuresMatched);

        /** Find school with Schola Plan active or claimed school  */
        const findProSchool = scoringSchools.findIndex((school) => school.isScholaPro);

        if (findProSchool >= 0) {
          setSponsoredSchool(scoringSchools[findProSchool]);
          scoringSchools.splice(findProSchool, 1);
        }

        // save search history
        const search = searchObject.search.defaultQuery;
        const ip = ipData.data.ip || '';
        const selectedSchools = scoringSchools.slice(0, 3);
        const features = location.query.features || '';

        saveSearchhistory(search, selectedSchools, preferedGrade, selectedTypes, features, ip);

        const schools = transformSchoolsForTable({
          schools: selectedSchools,
          features: featuresBucket || [],
        });

        onSuccess();
        setSearch(schools);
      })
      .catch((error) => {
        console.log('Error proccesing school match', error.message);
        onError();
      });
  };

  useEffect(() => {
    load();
  }, []);

  const [featuresBySponsored, setFeaturesBySponsored] = useState([]);
  useEffect(() => {
    if (sponsoredSchool) {
      const { featuresBySponsered } = transformSponsoredSchool(sponsoredSchool, featuresSet);
      setFeaturesBySponsored(featuresBySponsered);
    }
  }, [sponsoredSchool]);

  const handleContact = (school) => {
    dispatch(openContact());
    setContactSchoolSelected(school);
  };

  const schoolsToExplore = useMemo(() => {
    if (search.schoolsTransformed && sponsoredSchool) {
      return [sponsoredSchool, ...search.schoolsTransformed];
    }
    return search.schoolsTransformed;
  }, [search, sponsoredSchool]);

  return (
    <main className={styles.container}>
      <section className={styles.hero}>
        <div className={styles.hero_container}>
          <div className={styles.hero_badge_title}>
            <div>
              <img
                className={styles.badge_guaranteed}
                height="auto"
                src="https://static.schola.com/schola-match-results/best-match-guaranteed.png"
                alt="schola match guaranteed"
              />
            </div>
            <div>
              <h1 className={styles.h1}>Your Perfect School is One Click Away!</h1>
              <h5 className={styles.subtitle}>Your Preferences From ScholaMatch™</h5>
              <div className={styles.chipsWrapper}>
                {labels.length > 0 &&
                  labels.map((label, idx) => (label ? <ChipFeature key={idx}>{label}</ChipFeature> : null))}
              </div>
            </div>
          </div>
          <ScholaReview />
        </div>
      </section>

      {status.loading && <Skeleton height={300} />}

      {status.success && search.schoolsTransformed?.length > 0 && (
        <>
          <section>
            <div className={styles.titleBestMatch}>
              <h2 className={styles.title}>The Best Match For Your Child 👇</h2>
            </div>

            <BestMatchesTable
              schools={search}
              sponsoredSchool={sponsoredSchool}
              handleContact={handleContact}
              newListFeatures={search.newListFeatures}
              featuresBySponsored={featuresBySponsored}
            />
            <BannerSteps handleContact={handleContact} />
          </section>

          {sponsoredSchool && (
            <SponsoredSchool
              school={sponsoredSchool}
              featuresSelected={featuresSet}
              handleContact={() => handleContact(sponsoredSchool)}
            />
          )}

          <SchoolDetails schools={schoolsToExplore} handleContact={handleContact} />

          {isContactOpen && (
            <ContactSchoolModal
              school={contactSchoolSelected}
              source={source}
              searchHistoryId={searchHistoryId}
              externalCampaignId={externalCampaignId}
              autoFillForm={autoFillForm}
            />
          )}
        </>
      )}

      {status.success && search.schoolsTransformed?.length === 0 && <EmptySearch />}

      {/* temp hidden */}
      <SurveyFeedback searchHistoryId={searchHistoryId} />
    </main>
  );
};

const EmptySearch = () => {
  return (
    <section>
      <div className={styles.titleBestMatch}>
        <h2 className={styles.title}>
          Sorry! There is no schools available for your preferences. Try again with other match
        </h2>
      </div>
    </section>
  );
};
